import APIService from '@/services/api-service'
const resource = '/dispatch'
const subResource = 'report'

class DispatchReportService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getDispatchDates (request: any) {
    return this.connector.post(`${resource}/${subResource}/details`, request)
  }

  public getDispatch (id: any) {
    return this.connector.get(`${resource}/${subResource}/${id}`)
  }

  public getMonthlyDispatches (request: any) {
    return this.connector.post(`${resource}/${subResource}/monthly-dispatches`, request)
  }
}

export default new DispatchReportService()
