import APIService from '@/services/api-service'
const resource = '/report'

class SearchService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  /* public getJobsReport (serviceId) {
    return this.connector.get(`${resource}/${serviceId}/jobs-report`)
  } */

  public getJobsReport (data) {
    return this.connector.post(`${resource}/jobs-report`, data)
  }

  public getBargeReport (data) {
    return this.connector.post(`${resource}/barge-report`, data)
  }

  public getJobReportByStatus (data) {
    return this.connector.post(`${resource}/job-report`, data)
  }

  public getCategories () {
    return this.connector.get('service/categories')
  }
}

export default new SearchService()
