
import {
  Component,
  Vue
} from 'vue-property-decorator'
import DateHelper from '@/mixins/date-mixins'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import { AuthModule } from '@/store/modules/AuthModule'
import JobSummary from '@/views/summary/components/Job.vue'
import JobReport from './components/Job.vue'
import DispatchReport from './components/Dispatch.vue'
import BargeReport from './components/Barge.vue'
import QuickReport from './components/Quick.vue'

  @Component({
    name: 'Report',
    components: {
      JobSummary,
      JobReport,
      DispatchReport,
      BargeReport,
      QuickReport
    }
  })

export default class Report extends Vue {
  get date () {
    return DateHelper.today('dd/mm/yyyy')
  }

  get permissions () {
    return AuthModule.permissions
  }

  created (): void {
    this.boot()
  }

  protected boot () {
    BreadcrumbModule.setBreadcrumb(this.$route)
  }

  protected showComponent (type: string) {
    switch (type) {
      case 'jobSummary':
        (this.$refs.jobSummary as any).show()
        break

      case 'jobReport':
        (this.$refs.JobReport as any).show()
        break

      case 'dispatchReport':
        (this.$refs.DispatchReport as any).show()
        break

      case 'bargeReport':
        (this.$refs.BargeReport as any).show()
        break

      case 'quickReport':
        (this.$refs.QuickReport as any).show()
        break
    }
  }
}

