import APIService from '@/services/api-service'
const primary = '/job'
const secondary = 'form'

class FormService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getRunningNumber () {
    return this.connector.get(`${primary}/${secondary}/running-number`)
  }

  public getRefRunningNumber () {
    return this.connector.get(`${primary}/${secondary}/ref-running-number`)
  }

  public getCategories () {
    return this.connector.get('/service/categories')
  }

  public getServices (categoryId) {
    return this.connector.get(`/service/category/${categoryId}`)
  }

  public getInquiryNumbers () {
    return this.connector.get(`${primary}/${secondary}/inquiry-numbers`)
  }

  public getInquiry (number: any) {
    return this.connector.post(`${primary}/${secondary}/inquiry`, { number: { number } })
  }

  public getPortNames () {
    return this.connector.get('/port/names')
  }

  public getPortTerminals (portData: { id: any; name: any }) {
    return this.connector.get(`/port/terminals/${portData.id}/${portData.name}`)
  }

  public getClientNames () {
    return this.connector.get('/client/names')
  }

  public getCompanyNames () {
    return this.connector.get('/company/names')
  }

  public create (header: any, lines: any) {
    return this.connector.post(`${primary}/${secondary}/create`, { header: header, lines: lines })
  }

  public getJobDetail (id: any) {
    return this.connector.get(`${primary}/${secondary}/${id}/detail`)
  }

  public update (header: any, lines: any) {
    return this.connector.put(`${primary}/${secondary}/update`, { header: header, lines: lines })
  }

  public deleteJobLine (jobId: any, lineId: any) {
    return this.connector.delete(`${primary}/${secondary}/${jobId}/line/${lineId}`)
  }
}

export default new FormService()
