
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  utils,
  writeFile
} from 'xlsx-js-style'
import CollectionService from '@/views/job/services/collection-service'
import SearchService from '../services/search-service'
import Status from '@/components/Status/index.vue'
import {
  Printd
} from 'printd'

  @Component({
    name: 'BargeReport',
    components: {
      Status
    }
  })

export default class BargeReport extends Vue {
    protected isBusy = false
    protected showComponent = false
    protected isLoader = false
    protected isResult = false
    protected activities: any = []
    protected barges: any = []
    protected selected: any = {
      from_date: null,
      to_date: null,
      barge: {},
      barge_name: null
    }

    protected document: any = null
    protected styles = ['https://api.smc-spl-application.com/print.css']

    protected fields = [{
      key: 'date',
      label: 'Date',
      sortable: true,
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'job_number',
      label: 'Job No.',
      sortable: true,
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'quantities',
      label: 'Cargo - Qty',
      thStyle: {
        width: '20%'
      }
    },
    {
      key: 'barge_activities',
      label: 'Barge Activity',
      thStyle: {
        width: '65%'
      }
    }
    ]

    public show () {
      this.showComponent = true

      this.populateBarges()
      this.document = new Printd()
    }

    protected hide () {
      this.clear()
      this.showComponent = false
    }

    get rows () {
      return this.activities.length
    }

    protected populateBarges () {
      this.barges = []
      CollectionService.getBargeNames().then((response) => {
        response.data.forEach((barge: {
          id: any;name: any
        }) => {
          this.barges.push({
            value: {
              id: barge.id,
              name: barge.name
            },
            text: barge.name
          })
        })
      })
    }

    protected runReport () {
      this.selected.barge_name = this.selected.barge.name
      this.isResult = false
      this.isLoader = true
      SearchService.getBargeReport(this.selected).then((response) => {
        this.isLoader = false
        this.isResult = true
        this.activities = response.data.map(item => ({
          ...item,
          date: item.created_date,
          job_number: item.job_number,
          cargo_type: [...new Set(item.activities.map(activity => activity.service_type))],
          quantities: item.activities.reduce(function (res, activity) {
            if (!res[activity.service_type]) {
              res[activity.service_type] = {
                service_type: activity.service_type,
                quantity: 0
              }
            }
            res[activity.service_type].quantity += parseInt(activity.quantity)
            return res
          }, {}),
          barge_activities: item.activities.filter(({
            tripNumber
          }, index) =>
            !(item.activities.map(({
              tripNumber
            }) => tripNumber)).includes(tripNumber, index + 1))
        }))
      })
    }

    protected printReport () {
      this.$nextTick(() => {
        this.document.print(document.getElementById('print'), this.styles)
      })
    }

    protected downloadReport () {
      const activities = this.activities.map(item => ({
        Date: item.created_date,
        'Job Number': item.job_number,
        'Cargo Type': [...new Set(item.activities.map(activity => activity.service_type))].map(e => {
          return e
        }).join(', '),
        Quantity: item.activities.reduce(function (res, activity) {
          if (!res[activity.service_type]) {
            res[activity.service_type] = {
              service_type: activity.service_type,
              quantity: 0
            }
          }
          res[activity.service_type].quantity += parseInt(activity.quantity)
          return res
        }, {}) /* .map(e => { return e.service_type + ': ' + e.quantity }) */,
        'Barge Activity': item.activities.filter(({
          tripNumber
        }, index) =>
          !(item.activities.map(({
            tripNumber
          }) => tripNumber)).includes(tripNumber, index + 1)).map(e => {
          return 'Trip No: ' + e.tripNumber + '  ' + 'Commence: ' + e.commence_loading_date +
            ' ' + e.commence_loading_time + '  ' + 'Complete: ' + e.completed_loading_date + ' ' + e
            .completed_loading_time + '  ' + 'ROB: ' + e.remain_on_board
        }).join(', ')
        /* ROB: item.activities.filter(({
          tripNumber
        }, index) =>
          !(item.activities.map(({
            tripNumber
          }) => tripNumber)).includes(tripNumber, index + 1)).map(e => {
          return 'Trip No: ' + e.tripNumber + ' ' + 'ROB: ' + e.remain_on_board
        }).join(', ') */
      }))
      console.log(activities)

      /* const ws = utils.json_to_sheet(activities)
      const wb = utils.book_new()
      utils.book_append_sheet(wb, ws, 'Data')
      writeFile(wb, 'Barge Report.xlsx') */

      const headers = [[
        { v: 'Date', s: { font: { bold: true } } },
        { v: 'Job Number', s: { font: { bold: true } } },
        { v: 'Cargo Type', s: { font: { bold: true } } },
        { v: 'Quantity', s: { font: { bold: true } } },
        { v: 'Barge Activity', s: { font: { bold: true } } }
      ]]

      const wb = utils.book_new()
      const ws = utils.json_to_sheet([])
      utils.sheet_add_aoa(ws, headers)
      utils.sheet_add_json(ws, activities, { origin: 'A2', skipHeader: true })
      utils.book_append_sheet(wb, ws, 'Sheet1')
      writeFile(wb, 'Barge Report.xlsx')
    }

    public clear () {
      this.selected = {
        from_date: null,
        to_date: null,
        barge: {}
      }
      this.activities = []
      this.isResult = false
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

