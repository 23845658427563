
import {
  Component,
  Vue
} from 'vue-property-decorator'
import DateHelper from '@/mixins/date-mixins'
import Months from '@/data/months.json'
import ReportService from '../services/dispatch-report-service'

import {
  Printd
} from 'printd'
  @Component({
    name: 'DispatchReport'
  })

export default class DispatchReport extends Vue {
    protected showComponent = false
    protected isLoader = false
    protected isResult = false
    protected isRunReport = false
    protected document: any = null
    protected years: any = []
    protected year: any = null
    protected currentYear = DateHelper.year()
    protected months: any = []
    protected month: any = null
    protected types: any = ['Bowser', 'Garbage', 'Barge hire']
    protected type: any = null
    protected dispatchDates: any = []
    protected dispatchDate: any = null
    protected dispatches: any = []
    protected fromDate: any = null
    protected toDate: any = null
    protected totalQty: any = 0
    protected dekatanaQty: any = 0
    protected puttalamQty: any = 0
    protected styles = ['https://api.smc-spl-application.com/print.css']
    protected dispatch: any = {
      id: null,
      dispatch_date: null,
      from_location: null,
      dispatch_quantity: null,
      notes: []
    }

    protected dispatchFields: any = [{
      key: 'vehicle_number',
      label: 'Vehicle No.',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vehicle_type',
      label: 'Type',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'vehicle_capacity',
      label: 'Capacity',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'driver',
      label: 'Driver',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'commence_loading',
      label: 'Commence',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'completed_loading',
      label: 'Complete',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'content_type',
      label: 'Content',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'location_name',
      label: 'Location',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'total_tonnage',
      label: 'Tonnage',
      thStyle: {
        width: '10%'
      }
    }
    ]

    public show () {
      this.showComponent = true

      this.document = new Printd()
      this.setYears(2022)
      this.populateMonths()
    }

    protected hide () {
      this.clear()
      this.showComponent = false
    }

    get rows () {
      return this.dispatch.notes.length
    }

    protected setYears (startYear: number) {
      const years: any = []
      while (startYear <= this.currentYear) {
        years.unshift(startYear++)
      }
      this.years = years
    }

    protected populateMonths () {
      const currentYear = DateHelper.year()
      if (this.year === currentYear) this.months = Months
      else this.months = Months
    }

    protected populateDispatchDates () {
      this.dispatchDate = null
      this.dispatchDates = []
      const request = {
        year: this.year,
        month: this.month,
        type: this.type
      }

      ReportService.getDispatchDates(request).then((response) => {
        response.data.forEach((element) => {
          this.dispatchDates.push({
            value: {
              id: element.id,
              dispatch_date: element.dispatch_date,
              from_location: element.from_location,
              dispatch_quantity: element.dispatch_quantity
            },
            text: `${element.dispatch_date} - ${element.from_location}`
          })
        })
      })
      this.populateMonthlyDispatches()
    }

    protected hideTable () {
      this.dispatch = {
        id: null,
        dispatch_quantity: null,
        notes: []
      }
      this.isResult = false
    }

    protected runReport () {
      this.isRunReport = true
      if (this.dispatchDate !== null) this.populateDispatch(this.dispatchDate)
      else this.populateMonthlyDispatches()
    }

    protected populateMonthlyDispatches () {
      this.dispatches = []
      const request = {
        year: this.year,
        month: this.month,
        type: this.type
      }
      this.isLoader = true
      this.isResult = false
      ReportService.getMonthlyDispatches(request).then((response) => {
        this.isLoader = false
        if (response.data.length !== 0) {
          this.isResult = true
          this.dispatches = response.data.map(dispatch => ({
            ...dispatch,
            dispatch_quantity: dispatch.dispatch_quantity === null ? 0 : dispatch.dispatch_quantity,
            dekatana_qty: dispatch.notes.filter(note => note.location_name === 'Dekatana').reduce((sum,
              note) => {
              return sum + parseFloat(note.total_tonnage)
            }, 0),
            puttalam_qty: dispatch.notes.filter(note => note.location_name === 'Puttalam').reduce((sum,
              note) => {
              return sum + parseFloat(note.total_tonnage)
            }, 0),
            kerawalapitiya_qty: dispatch.notes.filter(note => note.location_name === 'Kerawalapitiya')
              .reduce((sum,
                note) => {
                return sum + parseFloat(note.total_tonnage)
              }, 0)
          }))

          this.totalQty = this.dispatches.reduce((total, dispatch) => {
            return total + parseFloat(dispatch.dispatch_quantity)
          }, 0)

          const dates: any = []
          this.dispatches.forEach(dispatch => {
            dates.push(
              new Date(dispatch.dispatch_date.split('/').reverse().join('/'))
            )
          })
          this.fromDate = DateHelper.formatDate(new Date(Math.min.apply(null, dates)))
          this.toDate = DateHelper.formatDate(new Date(Math.max.apply(null, dates)))

          this.dekatanaQty = this.dispatches.reduce((sum, dispatch) => {
            return sum + parseFloat(dispatch.dekatana_qty)
          }, 0)
          this.puttalamQty = this.dispatches.reduce((sum, dispatch) => {
            return sum + parseFloat(dispatch.puttalam_qty)
          }, 0)
        }
      })
    }

    protected populateDispatch (dispatch) {
      this.isLoader = true
      this.isResult = false
      this.dispatch.id = dispatch.id
      this.dispatch.dispatch_date = dispatch.dispatch_date
      this.dispatch.from_location = dispatch.from_location
      this.dispatch.dispatch_quantity = dispatch.dispatch_quantity
      ReportService.getDispatch(dispatch.id).then((response) => {
        this.isLoader = false
        this.isResult = true
        this.dispatch.notes = response.data.map(note => ({
          ...note,
          total_tonnage: note.total_tonnage === null ? 0 : note.total_tonnage
        }))
      })
    }

    protected print () {
      if (this.dispatchDate === null) this.printMonthReport()
      else this.printDayReport()
    }

    protected printDayReport () {
      this.$nextTick(() => {
        this.document.print(document.getElementById('dispatchDayReport'), this.styles)
      })
    }

    protected printMonthReport () {
      this.$nextTick(() => {
        this.document.print(document.getElementById('dispatchMonthlyReport'), this.styles)
      })
    }

    protected clear () {
      this.year = null
      this.month = null
      this.type = null
      this.dispatchDate = null
      this.dispatchDates = []
      this.dispatch = {
        id: null,
        dispatch_quantity: null,
        notes: []
      }
      this.isResult = false
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

