
import {
  Component,
  Vue
} from 'vue-property-decorator'
import SearchService from '../services/search-service'
import Status from '@/components/Status/index.vue'
import DateHelper from '@/mixins/date-mixins'
import FormService from '@/views/job/services/form-service'
import {
  utils,
  writeFile
} from 'xlsx'
import {
  Printd
} from 'printd'

  @Component({
    name: 'JobReport',
    components: {
      Status
    }
  })

export default class JobReport extends Vue {
    protected showComponent = false
    protected isLoader = false
    protected isResult = false
    protected jobs: any = []
    protected jobDetails: any = []
    protected services: any = []
    protected companies: any = []
    protected selected: any = {
      from_date: null,
      to_date: null,
      service: {},
      company: {}
    }

    protected document: any = null
    protected styles = ['https://api.smc-spl-application.com/print.css']

    public show () {
      this.showComponent = true

      this.populateServices()
      this.populateCompanyNames()
      this.document = new Printd()
    }

    protected hide () {
      this.clear()
      this.showComponent = false
    }

    get rows () {
      return this.jobs.length
    }

    protected searchJobs () {
      this.jobs = []
      this.isLoader = true
      this.isResult = false
      const obj = {
        from_date: this.selected.from_date,
        to_date: this.selected.to_date,
        category_id: this.selected.service,
        company_id: this.selected.company.id
      }
      SearchService.getJobsReport(obj).then((response) => {
        this.isLoader = false
        this.isResult = true
        this.jobs = response.data.map(job => ({
          job_number: job.job_number,
          operation_commenced_date: job.operation_commenced_date,
          vessel_name: job.vessel_name,
          quantity_collected: job.inventories.map(function (inventory) {
            return inventory.service_type + ' - ' + inventory.quantity
          }).join(' | '),
          oil: job.inventories.find(inventory => inventory.service_type === 'Oil'),
          water: job.inventories.find(inventory => inventory.service_type === 'Oil'),
          owners_charges: job.refund !== null ? job.refund.lines.filter(line => line.type === 'Staff')
            .reduce((sum, line) => {
              return sum + parseFloat(line.amount)
            }, 0) : 0,
          // owners_charges_currency: job.refund !== null ? job.refund.lines.find(line => line.type === 'Staff').currency : '',
          agent_commission: job.refund !== null ? job.refund.lines.filter(line => line.type === 'Agent')
            .reduce((sum, line) => {
              return sum + parseFloat(line.amount)
            }, 0) : 0,
          // agent_commission_currency: job.refund !== null ? job.refund.lines.find(line => line.type === 'Agent').currency : '',
          other_payment: job.refund !== null ? job.refund.lines.filter(line => line.type === 'Other')
            .reduce((sum, line) => {
              return sum + parseFloat(line.amount)
            }, 0) : 0,
          // other_payment_currency: job.refund !== null ? job.refund.lines.find(line => line.type === 'Other').currency : '',
          // operational_cost: job.total_actual_cost !== null ? job.total_actual_cost : 0,
          total_cost: (job.refund !== null ? job.refund.lines.reduce((sum, line) => {
            return sum + parseFloat(line.amount)
          }, 0) : 0),
          charges_collect: job.invoice !== null ? job.invoice.gross_amount : 0,
          invoice_currency: job.invoice !== null ? job.invoice.invoice_currency : null,
          spl: job.dispatch_note_lines.length !== 0 ? job.dispatch_note_lines.filter(line => line
            .location_id === 1).reduce((sum, line) => {
            return sum + parseFloat(line.tonnage)
          }, 0) : 0,
          dekatana: job.dispatch_note_lines !== 0 ? job.dispatch_note_lines.filter(line => line
            .location_id === 2).reduce((sum, line) => {
            return sum + parseFloat(line.tonnage)
          }, 0) : 0,
          other: 0,
          total: job.dispatch_note_lines.length !== 0 ? job.dispatch_note_lines.reduce((sum, line) => {
            return sum + parseFloat(line.tonnage)
          }, 0) : 0
        }))
      })
    }

    protected populateServices () {
      SearchService.getCategories().then((response) => {
        response.data.forEach((element: {
          id: any;name: any
        }) => {
          this.services.push({
            value: element.id,
            text: element.name
          })
        })
      })
    }

    protected download () {
      const obj = {
        from_date: this.selected.from_date,
        to_date: this.selected.to_date,
        category_id: this.selected.service,
        company_id: this.selected.company.id
      }
      SearchService.getJobsReport(obj).then((response) => {
        this.jobDetails = response.data.map(item => ({
          'Job Number': item.job_number,
          'Operation Commenced Date': item.operation_commenced_date,
          'Name of Vessel': item.vessel_name,
          'Qty Collected': item.inventories.map(function (e) { return e.quantity }).join(', '),
          'Oil Qty': item.inventories.map(function (e) { return e.oil_quantity }).join(', '),
          'Water Qty': item.inventories.map(function (e) { return e.water_quantity }).join(', '),
          'Owners Charges': item.refund !== null ? item.refund.lines.filter(line => line.type === 'Staff').reduce((sum, line) => { return sum + parseInt(line.amount) }, 0) : 0,
          'Agent Commission': item.refund !== null ? item.refund.lines.filter(line => line.type === 'Agent').reduce((sum, line) => { return sum + parseInt(line.amount) }, 0) : 0,
          'Other Payment': item.refund !== null ? item.refund.lines.filter(line => line.type === 'Other').reduce((sum, line) => { return sum + parseInt(line.amount) }, 0) : 0,
          'Operational Cost': 0,
          'Total Cost': item.refund !== null ? item.refund.lines.reduce((sum, line) => { return sum + parseInt(line.amount) }, 0) : 0,
          'Charges Collect': item.invoice !== null ? item.invoice.gross_amount : 0
        }))

        const ws = utils.json_to_sheet(this.jobDetails)
        const wscols = [
          { wch: 20 },
          { wch: 25 },
          { wch: 30 },
          { wch: 12 },
          { wch: 10 },
          { wch: 10 },
          { wch: 12 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 }
        ]

        ws['!cols'] = wscols
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Data')
        writeFile(wb, 'Report-' + DateHelper.today('yyyymmdd') + '.xlsx')
      })
    }

    protected downloadJobReport () {
      //
    }

    public clear () {
      this.selected = {
        from_date: null,
        to_date: null,
        service: {},
        company: {}
      }
      this.jobs = []
      this.jobDetails = []
      this.services = []
      this.companies = []
      this.isResult = false
      this.isLoader = false
    }

    protected async populateCompanyNames () {
      const response = await FormService.getCompanyNames()
      response.data.forEach((element) => {
        this.companies.push({
          value: {
            id: element.id,
            name: element.name
          },
          text: element.name
        })
      })
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

